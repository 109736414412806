import React, {FC, useEffect, useState} from 'react';
import styles from "./ImageGallery.module.css";
import GalleryDesktop from "../GalleryDesktop/GalleryDesktop";
import GalleryPhone from "../GalleryPhone/GalleryPhone";
import {IPhoto} from "../../../types";

interface IProps {
    photos?: IPhoto[];
}

const ImageGallery: FC<IProps> = ({photos = []}) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const windowSizeHandler = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener("resize", windowSizeHandler);

        return () => {
            window.removeEventListener("resize", windowSizeHandler);
        };
    }, [])

    if (photos?.length === 0) {
        return (<></>)
    }

    return (
        <div>
            <p className={styles.title}>Education</p>
            {windowWidth > 600
                ? <GalleryDesktop photos={photos} windowWidth={windowWidth}/>
                : <GalleryPhone photos={photos}/>
            }
        </div>
    );
};

export default ImageGallery;