import React, {FC, useEffect} from 'react';
import {useSwiper} from "swiper/react";

interface IProps {
    position: number
}

const ChangeSlide: FC<IProps> = ({position}) => {
    const swiper = useSwiper();

    useEffect(() => {
        if (swiper) {
            swiper.slideTo(position)
        }
    }, [swiper, position])
    return null
};

export default ChangeSlide;