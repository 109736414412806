import React, {ChangeEvent, FC, useEffect} from 'react';
import styles from './CheckboxGroup.module.css';
import {validateSet} from "../../../validation/validation";
import {IParentSymptom} from "../../../types";

interface CheckboxGroupProps {
    groupStates: IParentSymptom;
    group: string;
    symptoms: Set<number>;
    setSymptoms: Function;
    setSymptomsWarning: Function;
    setSymptomsError: Function;
    setIsSymptomsValid: Function;
    isBtnClicked: boolean;
}

const CheckboxGroup: FC<CheckboxGroupProps> =
    ({
         groupStates, group, symptoms, setSymptoms,
         setSymptomsError, setSymptomsWarning, setIsSymptomsValid, isBtnClicked
    }) => {

    useEffect(() => {
        (isBtnClicked || symptoms.size > 0) && checkCheckboxGroup();
    }, [isBtnClicked, symptoms]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        let symptomsChanged: Set<number> = new Set(symptoms.values());
        let targetId = Number(event.target.id.split('-')[1]);
        if (event.target.checked) {
            symptomsChanged.add(targetId);
        } else {
            symptomsChanged.delete(targetId);
        }
        setSymptoms(symptomsChanged);
    };

    const checkCheckboxGroup = () => {
        const isCheckboxGroupValid = validateSet(symptoms);
        if (isCheckboxGroupValid) {
            setSymptomsError('valid');
            setSymptomsWarning('');
        } else {
            setSymptomsError('invalid');
            setSymptomsWarning('Please choose at least one symptom');
        }
        setIsSymptomsValid(isCheckboxGroupValid);
    };

    return (
        <div className={styles.checkbox__group}>
            {groupStates.kids.map(({id, symptom}) => (
                <div className={styles.checkbox__field} key={id}>
                    <input
                        className={styles.custom__checkbox} type="checkbox"
                        id={`${groupStates.id}-${id}`} name={group} value={symptom}
                        onChange={handleChange} checked={symptoms.has(id)}
                    />
                    <label htmlFor={`${groupStates.id}-${id}`}>{symptom}</label>
                </div>
            ))}
        </div>
    );
};

export default CheckboxGroup;
