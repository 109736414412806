import React, {FC} from 'react';
import cn from "classnames";
import styles from "./ModalBox.module.css";
import SwiperContainer from "../SwiperContainer/SwiperContainer";
import {IPhoto} from "../../../types";

interface IProps {
    isVisible: boolean,
    handleCloseModal: () => void
    photos?: IPhoto[];
    initialSlide: number;
}

const ModalBox: FC<IProps> = ({isVisible, handleCloseModal, photos, initialSlide}) => {
    const modal = document.getElementById('ModalBox');

    window.onclick = function(event) {
        if (event.target == modal) {
            if (modal) handleCloseModal();
        }
    }

    return (
        <div className={cn(styles.modal, isVisible && styles.visible)} id={"ModalBox"}>
            <div className={styles.modal__content} >
                <div className={styles.modal__header}>
                    <span className={styles.close} onClick={handleCloseModal}>&times;</span>
                </div>
                <SwiperContainer photos={photos} initialSlide={initialSlide}/>
            </div>
        </div>
    );
};

export default ModalBox;