import React, {useEffect, useState} from 'react';
import styles from './SpecialistsList.module.css';
import CardSpecialist from "../../../components/specialists/CardSpecialist/CardSpecialist";
import cn from "classnames";
import SpecialistsService from "../../../API/SpecialistsService";
import Loader from "../../../components/library/Loader/Loader";
import {Link, useNavigate} from "react-router-dom";
import {IPsychologist} from "../../../types";

const SpecialistsList = () => {
    const [specialists, setSpecialists] = useState<IPsychologist[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigation = useNavigate();

    useEffect(() => {
        fetchSpecialists();
        setTimeout(() => {
            window.scrollTo(0,0)
        })
    }, []);

    async function fetchSpecialists () {
        setIsLoading(true);
        setTimeout(async () => {
            try {
                const response = await SpecialistsService.getAll();
                setSpecialists(response);
                setIsLoading(false);
            } catch (err) {
                console.log(err);
                navigation('/error');
            }
        }, )
    }

    return (
        <div className={cn('container', styles.specialists, isLoading && styles.loading)}>
            {isLoading
                ? <Loader/>
                : <div>
                    <h3 className={styles.header}>
                        We have selected {specialists.length} {specialists.length === 1 ? 'specialist' : 'specialists'} for you
                    </h3>
                    <p className={styles.text}>These specialists know how to work with your request,
                        they are suitable for the conditions of the order. Now they are free - you can start the session
                    </p>
                    <hr className={styles.hr}/>
                    {
                        specialists.map((specialist, index) => (
                            <div className={styles.margin} key={index}>
                                <Link to={`/specialists/${specialist.id}`}>
                                    <CardSpecialist specialist={specialist}/>
                                </Link>
                                <hr className={styles.hr}/>
                            </div>
                        ))
                    }
                </div>
            }
        </div>
    );
};

export default SpecialistsList;
