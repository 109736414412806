import React, {FC} from 'react';
import styles from './CardStep.module.css';
import Button from "../../library/Button/Button";
import cn from "classnames";
import {Link} from "react-router-dom";

export interface CardStepProps {
    color: "green" | "blue_light";
    hasButton: boolean;
    header: string;
    text: string;
    id?: number;
}

const CardStep: FC<CardStepProps> =
    ({
         color,
         hasButton,
         header,
         text,
         id
    }) => {
    return (
        <div className={cn(styles.card, styles[color], styles[`card__${id}`])}>
            <h3 className={cn(styles.h3, color === "green" ? styles.h3__green : styles.h3__blue_light)}>{header}</h3>
            <p className={color === "green" ? styles.p__green : styles.p__blue_light}>{text}</p>
            {hasButton && <Link to={'/registration'}><Button appearance={'green'}>Fill in a form</Button></Link>}
        </div>
    );
};

export default CardStep;
