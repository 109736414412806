import React, {FC} from 'react';
import styles from "./GalleryPhone.module.css";
import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination} from "swiper/modules";
import 'swiper/css';
import 'swiper/css/pagination';
import {IPhoto} from "../../../types";

interface IProps {
    photos?: IPhoto[];
}

const GalleryPhone: FC<IProps> = ({photos= []}) => {
    return (
        <Swiper modules={[Pagination]} pagination={true} className={styles.mySwiper2} autoHeight={true}>
            {photos.map((photo, index) => (
                <SwiperSlide  key={index}>
                    <div className={styles.slide__container}>
                        <img src={photo.photo} alt={'diplomas photos'} className={styles.photo_slider}/>
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default GalleryPhone;