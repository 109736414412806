import React, {FC} from 'react';
import styles from './SpecialistAbout.module.css';
import SpecialistSymptoms from "../../../components/specialists/SpecialistSymptoms/SpecialistSymptoms";
import CardFeatures from "../../../components/specialists/CardFeatures/CardFeatures";
import ImageGallery from "../../../components/specialists/ImageGallery/ImageGallery";
import {IPsychologist} from "../../../types";
import TextBlock from "../../../components/library/TextBlock/TextBlock";

interface SpecialistAboutProps {
    specialist?: IPsychologist;
}

const SpecialistAbout: FC<SpecialistAboutProps> = ({specialist}) => {
    return (
        <div>
            <h3 className={styles.name}>{specialist?.name}</h3>
            <TextBlock text={specialist?.about} small={false}/>
            <CardFeatures specialist={specialist}/>
            <SpecialistSymptoms symptoms={specialist?.symptoms} amountShown={6} isTitleShown={true}/>
            <TextBlock title={'How I work'} text={specialist?.work_method} small={false}/>
            <ImageGallery photos={specialist?.education_photos}/>
        </div>
    );
};

export default SpecialistAbout;
