import React, {useEffect, useState} from 'react';
import styles from './PsychologistInfo.module.css';
import cn from "classnames";
import SpecialistsService from "../../../API/SpecialistsService";
import SpecialistSidebar from "../SpecialistSidebar/SpecialistSidebar";
import SpecialistAbout from "../SpecialistAbout/SpecialistAbout";
import {useNavigate} from "react-router-dom";
import Loader from "../../../components/library/Loader/Loader";
import {IPsychologist} from "../../../types";

export const getSpecialistId = () => {
    const pathname = window.location.pathname;
    return Number(pathname.split('/')[2]);
}

const PsychologistInfo = () => {
    const [specialist, setSpecialist] = useState<IPsychologist>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigation = useNavigate();

    useEffect(() => {
        fetchSpecialist();
    }, []);

    async function fetchSpecialist () {
        setIsLoading(true);
        setTimeout(async () => {
            try {
                const response = await SpecialistsService.getById(getSpecialistId());
                setSpecialist(response);
                setIsLoading(false);
            } catch (err) {
                console.log(err);
                navigation('/error');
            }
        }, )
    }

    return (
        <div className={cn('container', styles.psychologist__container, isLoading && styles.loading)}>
            {isLoading
                ? <Loader/>
                : <div className={styles.psychologist_info}>
                    <SpecialistSidebar photo={specialist?.photo} setIsLoading={setIsLoading}/>
                    <SpecialistAbout specialist={specialist}/>
                </div>
            }
        </div>
    );
};

export default PsychologistInfo;
