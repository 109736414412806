import axios, {AxiosResponse} from "axios";

export default class RegistrationService {
    static async sendRegistrationData (name: string, age: number, experience: boolean, email: string): Promise<boolean> {
        return await axios.post(`${process.env.REACT_APP_HOST}/api/v1/registration/`, {
            name: name,
            old: age,
            email: email,
            has_experience: experience
        })
            .then(function (response) {
                localStorage.setItem('token', response.data.token);
                return true;
            })
            .catch(function (error) {
                console.log(error.message);
                return false;
            });
    }

    static async getUserEmail () {
        const token = localStorage.getItem('token');
        return axios.get(`${process.env.REACT_APP_HOST}/api/v1/profile/`, {
            headers: {
                Authorization: `Token ${token}`
            }
        })
    }
}
