import React, {FC, useEffect, useState} from 'react';
import styles from "./GalleryDesktop.module.css";
import ModalBox from "../../library/ModalBox/ModalBox";
import {IPhoto} from "../../../types";

interface IProps {
    photos?: IPhoto[];
    windowWidth: number;
}

const GalleryDesktop: FC<IProps> = ({photos = [], windowWidth}) => {
    const [initialSlide, setInitialSlide] = useState(0);
    const [isVisible, setIsVisible] = useState(false);
    const [scrollY, setScrollY] = useState(0)

    useEffect(() => {
        if (windowWidth < 600) onCloseModal();
    }, [windowWidth]);

    useEffect(() => {
        const windowScrollHandler = () => {
            setScrollY(window.scrollY);
        };
        window.addEventListener("scroll", windowScrollHandler);

        return () => {
            window.removeEventListener("scroll", windowScrollHandler);
        };
    }, [])

    const onOpenModal = (index: number) => {
        setIsVisible(true);
        setInitialSlide(index);
        let page = document.getElementById('page');
        if (page) {
            page.classList.add(styles.modal_open);
            page.style.top = `-${scrollY}px`
        }
        let modal = document.getElementById('ModalBox')
        if (modal) modal.style.top = `${scrollY}px`;
    }

    const onCloseModal = () => {
        setIsVisible(false);
        let page = document.getElementById('page')
        if (page) {
            page.classList.remove(styles.modal_open);
            const scrollY = page.style.top;
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
        }
    }

    return (
        <div>
            <div className={styles.photo__container}>
                {photos.map((photo, index) => (
                    <img
                        src={photo.photo} alt={'diplomas photos'} className={styles.photo} key={index}
                        onClick={() => onOpenModal(index)}
                    />
                ))}
            </div>
            <ModalBox isVisible={isVisible} handleCloseModal={onCloseModal} photos={photos} initialSlide={initialSlide}/>
        </div>
    );
};

export default GalleryDesktop;
