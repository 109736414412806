import React, {FC} from 'react';
import styles from "./SpecialistFeatures.module.css";
import SparkIcon from "../../../static/images/spark.svg";
import CoinIcon from "../../../static/images/coin.svg";
import LocationIcon from "../../../static/images/location-grey.svg";
import TextBlockWithIcon from "../../library/TextBlockWithIcon/TextBlockWithIcon";

interface SpecialistFeaturesProps {
    experience: number;
    costOnline: number;
    costOffline: number;
    location: string
}

const costToArr = (costOnline: number, costOffline: number) => {
    const res = [];
    costOnline && res.push(`R${costOnline} / online`);
    costOffline && res.push(`R${costOffline} / offline`);
    return res;
}

const SpecialistFeatures: FC<SpecialistFeaturesProps> =
    ({
         experience, costOnline, costOffline, location
    }) => {

    return (
        <div className={styles.features}>
            <TextBlockWithIcon src={SparkIcon} text={[`${experience} ${experience === 1 ? 'year' : 'years'} experience`]}/>
            <TextBlockWithIcon src={CoinIcon} text={costToArr(costOnline, costOffline)}/>
            {location && <TextBlockWithIcon src={LocationIcon} text={[location]}/>}
        </div>
    );
};

export default SpecialistFeatures;
