import React, {FC} from 'react';
import styles from "./TextBlock.module.css";

interface IProps {
    title?: string;
    text?: string;
    small: boolean;
}

const TextBlock: FC<IProps> = ({title, text = '', small}) => {
    if (text.length === 0) {
        return <></>
    }

    return (
        <div>
            <p className={small ? styles.title_small : styles.title}>{title}</p>
            <p className={small ? styles.text_small : styles.text} dangerouslySetInnerHTML={{__html: text}}/>
        </div>
    );
};

export default TextBlock;