import React, {useEffect, useState} from 'react';
import styles from './FeedbackMessage.module.css';
import TickIcon from '../../../static/images/tick.svg';
import Button from "../../../components/library/Button/Button";
import cn from "classnames";
import {Link} from "react-router-dom";
import RegistrationService from "../../../API/RegistrationService";
import Loader from "../../../components/library/Loader/Loader";

const FeedbackMessage = () => {
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchUserEmail = async () => {
            setIsLoading(true);
            await RegistrationService.getUserEmail()
                .then(response => {
                    setEmail(response.data.email);
                    setIsLoading(false);
                })
                .catch(error => console.log(error));
        }

        fetchUserEmail();
    }, [])

    if (isLoading) {
        return (<div className={cn('container', styles.message__container)}><Loader/></div>);
    }

    return (
        <div className={cn('container', styles.message__container)}>
            <input className={styles.icon} type={'image'} src={TickIcon} alt={'tick'}/>
            <p className={cn(styles.text, 'text__light')}>
                Within an hour, we will set up a WhatsApp chat for you with the specialist of your choice.<br/>
                User email: <span className={styles.email}>{email}</span>
            </p>
            <Link to={'/'}>
                <Button appearance={'blue'}>To the main page</Button>
            </Link>
        </div>
    );
};

export default FeedbackMessage;
