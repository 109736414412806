import React, {FC} from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Pagination} from "swiper/modules";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import styles from "./SwiperContainer.module.css";
import ChangeSlide from "../ChangeSlide/ChangeSlide";
import {IPhoto} from "../../../types";

interface IProps {
    photos?: IPhoto[];
    initialSlide: number;
}

const SwiperContainer: FC<IProps> = ({photos = [], initialSlide}) => {
    return (
        <Swiper
            navigation={true} pagination={true}
            modules={[Pagination, Navigation]} spaceBetween={30}
            className={`mySwiper1`} observer={'true'} autoHeight={true}
        >
            <ChangeSlide position={initialSlide}/>
            {photos.map((photo, index) => (
                <SwiperSlide key={index}>
                    <div className={styles.slide__container} >
                        <img src={photo.photo} alt={'diplomas photos'} className={styles.photo_slider}/>
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default SwiperContainer;