import React, {FC} from 'react';
import styles from './CardSpecialist.module.css';
import Button from "../../library/Button/Button";
import SpecialistFeatures from "../SpecialistFeatures/SpecialistFeatures";
import SpecialistSymptoms from "../SpecialistSymptoms/SpecialistSymptoms";
import SpecialistPhoto from '../../../static/images/user.png';
import {IPsychologist} from "../../../types";

interface CardSpecialistProps {
    specialist: IPsychologist;
}

const CardSpecialist: FC<CardSpecialistProps> = ({specialist}) => {
    return (
        <div className={styles.card}>
            <input
                className={styles.photo}
                type={"image"}
                src={specialist.photo ? specialist.photo : SpecialistPhoto} 
                alt={'Specialist'}
            />
            <h3 className={styles.name}>{specialist.name}</h3>
            {specialist.about && <p className={styles.about} dangerouslySetInnerHTML={{__html: specialist.about}}/>}
            <SpecialistSymptoms symptoms={specialist.symptoms} amountShown={3} isTitleShown={false}/>
            <SpecialistFeatures
                experience={specialist.experience}
                costOnline={specialist.online_cost} costOffline={specialist.offline_cost}
                location={specialist.location}
            />
            <div className={styles.button}>
                <Button appearance={'blue_wide'}>Open profile</Button>
            </div>
        </div>
    );
};

export default CardSpecialist;
