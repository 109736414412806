import React, {FC} from 'react';
import styles from "./TextBlockWithIcon.module.css";

interface IProps {
    text?: string[];
    src: string;
}

const TextBlockWithIcon: FC<IProps> = ({text= [], src}) => {
    if (text.length === 0) {
        return <></>
    }

    return (
        <div className={styles.container}>
            <input className={styles.icon} type={"image"} src={src}/>
            <div>
                {text?.map((elem, index) => (
                    <p className={styles.text} key={index}>{elem}</p>
                ))}
            </div>
        </div>
    );
};

export default TextBlockWithIcon;