import React, {FC, useEffect, useState} from 'react';
import styles from './CheckboxQuestion.module.css';
import cn from "classnames";
import CheckboxGroup from "../CheckboxGroup/CheckboxGroup";
import PersonalQuestionsService from "../../../API/PersonalQuestionsService";
import Loader from "../Loader/Loader";
import {IParentSymptom} from "../../../types";

interface CheckboxQuestionProps {
    symptoms: Set<number>;
    setSymptoms: Function;
    setIsSymptomsValid: Function;
    isBtnClicked: boolean;
    setSymptomsId: Function;
}

const CheckboxQuestion: FC<CheckboxQuestionProps> =
    ({
         symptoms, setSymptoms, setIsSymptomsValid, isBtnClicked, setSymptomsId
    }) => {
    const [symptomsWarning, setSymptomsWarning] = useState<string>('');
    const [symptomsError, setSymptomsError] = useState<'' | 'valid' | 'invalid'>('');
    const [symptomsGroup, setSymptomsGroup] = useState<IParentSymptom[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        fetchSymptoms();
    }, []);

    useEffect(() => {
        if (symptomsGroup.length > 0) {
            const idArr: Set<number> = new Set();
            symptomsGroup.map((group) => {
                idArr.add(group.id);
                group.kids.map((kid) => {
                    idArr.add(kid.id);
                })
            });
            setSymptomsId(idArr);
        }
    }, [symptomsGroup]);

    async function fetchSymptoms () {
        setIsLoading(true);
        try {
            const symptomsGroupObj = await PersonalQuestionsService.getSymptoms();
            setSymptomsGroup(symptomsGroupObj);
            setIsLoading(false);
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div className={'container_form'}>
            {isLoading
                ? <Loader/>
                : <div>
                    <p className={styles.question}>Expressions</p>
                    {symptomsGroup?.map((group) => (
                        <div key={group.id}>
                            <p className={cn(styles.question__type, styles.question)}>{group.symptom}</p>
                            <CheckboxGroup
                                groupStates={group} group={group.symptom} symptoms={symptoms} setSymptoms={setSymptoms}
                                setIsSymptomsValid={setIsSymptomsValid} setSymptomsError={setSymptomsError}
                                setSymptomsWarning={setSymptomsWarning} isBtnClicked={isBtnClicked}
                            />
                        </div>
                    ))}
                    { symptomsWarning &&
                        <p className={cn(styles.warning, symptomsError === 'invalid' && styles.error)}>{symptomsWarning}</p>
                    }
                </div>
            }
        </div>
    );
};

export default CheckboxQuestion;
