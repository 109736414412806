import React, {FC} from 'react';
import styles from "./SpecialistSymptoms.module.css";
import {IKidSymptom} from "../../../types";

interface SpecialistSymptomsProps {
    symptoms?: IKidSymptom[];
    amountShown: number;
    isTitleShown: boolean;
}

const SpecialistSymptoms: FC<SpecialistSymptomsProps> = ({symptoms= [], amountShown , isTitleShown}) => {
    const symptomsShown: string[] = [];
    amountShown = Math.min(symptoms?.length, amountShown);
    if (symptoms?.length > 0) {
        for (let i = 0; i < amountShown; i++) {
            symptomsShown.push(symptoms[i]?.symptom);
        }
        let amountNotShown = symptoms?.length - amountShown;
        if (amountNotShown > 0) {
            symptomsShown.push(`+ ${amountNotShown} more`);
        }
    }

    if (symptoms?.length === 0) {
        return <></>;
    }

    return (
        <div className={styles.symptoms}>
            {isTitleShown && <p className={styles.title}>My expertise</p>}
            <div className={styles.symptoms__container}>
                {symptomsShown.map((symptom, index) => (
                    <div className={styles.symptoms__elem} key={index}>
                        <p className={styles.symptoms__elem_text}>{symptom}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SpecialistSymptoms;
