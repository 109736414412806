import React, {FC} from 'react';
import styles from './CardFeatures.module.css';
import SessionCost from "../SessionCost/SessionCost";
import TextBlock from "../../library/TextBlock/TextBlock";
import {ILanguage, IPsychologist} from "../../../types";

interface CardFeaturesProps {
    specialist?: IPsychologist;
}

const languagesArrToStr = (arr?: ILanguage[]) => {
    if (!arr) {
        return '';
    }
    return arr.reduce((accumulator, elem) => {
        return accumulator ? `${accumulator}, ${elem.name}` : elem.name;
    }, '');
}

const CardFeatures: FC<CardFeaturesProps> = ({specialist}) => {

    const hasCost = specialist?.online_cost !== null || specialist?.offline_cost !== null;

    return (
        <div className={styles.features}>
            <div className={styles.feature}>
                <TextBlock
                    small={true} title={'Work experience'}
                    text={`${specialist?.experience} ${specialist?.experience === 1 ? 'year' : 'years'}`}
                />
            </div>
            {hasCost &&
                <div className={styles.feature}>
                    <SessionCost costOnline={specialist?.online_cost} costOffline={specialist?.offline_cost}/>
                </div>
            }
            {specialist?.location &&
                <div className={styles.feature}>
                    <TextBlock small={true} title={'Location'} text={specialist?.location ? specialist.location : ''}/>
                </div>
            }
            {specialist?.languages.length !== 0 &&
                <div className={styles.feature}>
                    <TextBlock small={true} title={'Languages'} text={languagesArrToStr(specialist?.languages)}/>
                </div>
            }
        </div>
    );
};

export default CardFeatures;
