import React, {useEffect} from 'react';
import Navbar from "../components/library/Navbar/Navbar";
import BackgroundElipse from "../static/images/background-elipse.svg";
import MainBlock from "../layout/mainPage/MainBlock/MainBlock";
import Advantages from "../layout/mainPage/Advantages/Advantages";
import Problems from "../layout/mainPage/Problems/Problems";
import Quote from "../layout/mainPage/Quote/Quote";
import Steps from "../layout/mainPage/Steps/Steps";
import Team from "../layout/mainPage/Team/Team";
import Footer from "../components/library/Footer/Footer";
import FAQ from "../layout/mainPage/FAQ/FAQ";
import {useLocation} from "react-router-dom";

const Main = () => {
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const paramsObject: {[key: string]: string} = {};
        queryParams.forEach((value, key) => {
            paramsObject[key] = value;
        });
        localStorage.setItem('utmParams', JSON.stringify(paramsObject));
    }, [location]);

    return (
        <div>
            <div className="container">
                <input type={"image"} className="background" src={BackgroundElipse} alt={"background"}/>
            </div>
            <Navbar/>
            <MainBlock/>
            <Advantages/>
            <Problems/>
            <Quote
                hasButton={false}
                text={"We maintain high quality standards. Only the best psychologists are available to you"}
            />
            <Steps/>
            <Quote hasButton={false} text={"Psychotherapy is a tool that helps you live better"}/>
            <Team/>
            <Quote
                hasButton={true}
                text={"Take the first step to a more balanced you with a professional psychologist"}
            />
            <FAQ/>
            <Footer/>
        </div>
    );
};

export default Main;
