import React, {FC} from 'react';
import styles from "./SessionCost.module.css";

interface IProps {
    costOnline?: number;
    costOffline?: number
}

const SessionCost: FC<IProps> = ({costOnline, costOffline}) => {
    if (!costOffline && !costOnline) {
        return <></>
    }

    return (
        <div>
            <p className={styles.title}>Cost of one session</p>
            {costOnline && <p className={styles.text}>
                <span className={styles.text_bold}>R{costOnline}</span> / online
            </p>}
            {costOffline && <p className={styles.text}>
                <span className={styles.text_bold}>R{costOffline}</span> / offline
            </p>}
        </div>
    );
};

export default SessionCost;
