import React from 'react';
import styles from './ErrorMessage.module.css';
import ErrorIcon from '../../../static/images/error.svg';
import Button from "../../../components/library/Button/Button";
import cn from "classnames";
import {Link} from "react-router-dom";

const ErrorMessage = () => {
    return (
        <div className={cn('container', styles.message__container)}>
            <input className={styles.icon} type={'image'} src={ErrorIcon} alt={'tick'}/>
            <p className={cn(styles.text, 'text__light')}>
                OOPS...<br/>
                Server error occurred.<br/>
                Please try again later!
            </p>
            <Link to={'/'}>
                <Button appearance={'blue'}>To the main page</Button>
            </Link>
        </div>
    );
};

export default ErrorMessage;
