import React from 'react';
import styles from './Footer.module.css';

const footerLinks = [
    {
        'href': "https://balanceonline.notion.site/About-Us-a9f696ef6bee43c8b77e00f97c451ce4",
        'title': "About us"
    },
    {
        'href': "https://balanceonline.notion.site/Privacy-Policy-71496c75f8eb44ddb48e3c47b74c5492?pvs=4",
        'title': "Privacy policy"
    },
    {
        'href': "https://balanceonline.notion.site/Terms-and-Conditions-c9d981e702ff430581dec5371977713b?pvs=4",
        'title': "Terms and Conditions"
    },
    {
        'href': "https://balanceonline.notion.site/Terms-and-Conditions-of-Use-for-Therapists-d2f9a83eb778488c8ab135d213bd7d93",
        'title': "Terms and Conditions of Use for Therapists"
    },
]

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className="container">
                <hr className={styles.hr}/>
                <div className={styles.content}>
                    {footerLinks.map((link, index) =>(
                        <a href={link.href} target={"_blank"} className={styles.a} key={index}>{link.title}</a>
                    ))}
                    <p className={styles.company}>© {new Date().getFullYear()}, Balance</p>
                </div>
                <hr className={styles.hr}/>
            </div>
        </footer>
    );
};

export default Footer;
